import {
  makeSelectSpecificBrand,
  selectBrandLoading,
  makeSelectSpecificBrandFromSearchList,
  selectBrandsSearchResultsIds,
  selectBrandSearchLoading,
  selectBrandSearchFailed,
} from "selectors/brand";

import useReduxState from "hooks/useReduxState";

export function useBrandData(brandId) {
  const selectSpecificBrand = makeSelectSpecificBrand();

  const brand = useReduxState(
    (state) => selectSpecificBrand(state, brandId),
    [brandId]
  );

  return brand;
}

export function useBrandHubSearchData(brandId) {
  const selectSpecificBrand = makeSelectSpecificBrandFromSearchList();

  const brand = useReduxState(
    (state) => selectSpecificBrand(state, brandId),
    [brandId]
  );

  return brand;
}

export function useBrandHubSearchIdData() {
  const brands = useReduxState(
    (state) => selectBrandsSearchResultsIds(state),
    []
  );

  return brands;
}

export function useBrandDataIsLoading(brandId) {
  const loading = useReduxState(
    (state) => selectBrandLoading(state, brandId),
    [brandId]
  );

  return loading;
}

export function useBrandSearchIsLoading() {
  const loading = useReduxState((state) => selectBrandSearchLoading(state), []);

  return loading;
}

export function useBrandSearchFailed() {
  const loading = useReduxState((state) => selectBrandSearchFailed(state), []);

  return loading;
}

export default useBrandData;
