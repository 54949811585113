import { List } from "immutable";
import { createSelector } from "reselect";

export const selectBrands = (state) =>
  state.brand.getIn(["brands", "entities"]);
export const selectBrandsSearchResults = (state) =>
  state.brand.getIn(["brandResults", "entities"]);
export const selectBrandsSearchResultsIds = (state) =>
  state.brand.getIn(["brandResults", "brandIds"]);

const selectSpecificBrand = createSelector(
  [selectBrands, (_, brandId) => brandId],
  (brands, brandId) => brands.get(brandId?.toString())
);

const selectSpecificBrandFromSearchList = createSelector(
  [selectBrandsSearchResults, (_, brandId) => brandId],
  (brands, brandId) => brands.get(brandId?.toString())
);

export const makeSelectSpecificBrand = () => selectSpecificBrand;
export const makeSelectSpecificBrandFromSearchList = () =>
  selectSpecificBrandFromSearchList;

const selectBrandsByPodcastId = createSelector(
  [selectBrands, (_, podcastId) => podcastId],
  (brands, podcastId) => {
    if (!podcastId) {
      return List();
    }

    return brands
      .filter((brand) => {
        if (brand.get("sponsored_podcasts")?.size === 0) {
          return false;
        }

        return brand
          .get("sponsored_podcasts")
          .some(
            (sponsoredPodcast) =>
              sponsoredPodcast.get("podcast_id") === podcastId
          );
      })
      .toList();
  }
);

export const makeSelectBrandsByPodcastId = () => selectBrandsByPodcastId;

export const selectBrandLoading = (state, brandId) =>
  state?.brand?.getIn(["brands", "loading", brandId?.toString()]);
export const selectBrandFailed = (state, brandId) =>
  state?.brand?.getIn(["brands", "failed", brandId?.toString()]);

export const selectBrandSearchLoading = (state) =>
  state?.brand?.getIn(["brandResults", "loading"]);
export const selectBrandSearchFailed = (state) =>
  state?.brand?.getIn(["brandResults", "failed"]);
