import { Map } from "immutable";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import { getBaseUrl } from "constants/base";
import { getBrandUrlByEntity } from "utils/entity/getBrandUrl";

const BrandSharingLinks = (props) => {
  const { brand, eventProps: passedEventProps } = props;

  const { info, eventProps } = useMemo(
    () => ({
      info: {
        url: `${getBaseUrl()}${getBrandUrlByEntity(brand)}`,
        title: `${brand.get("name")} on Podchaser`,
        twitterTitle: `${brand.get("name")} on @Podchaser`,
        image_url: `${brand.get("image_url")}`,
        twitterHandle:
          brand.getIn([
            "settings",
            "additional_links",
            "social_links",
            "twitter",
          ]) || null,
      },
      eventProps: {
        brand_id: brand.get("id"),
        ...passedEventProps,
      },
    }),
    [passedEventProps, brand]
  );

  return (
    <EntitySharingLinks
      {...props}
      entity={brand}
      entity_type="brand"
      info={info}
      eventProps={eventProps}
    />
  );
};

BrandSharingLinks.propTypes = {
  brand: PropTypes.instanceOf(Map),
  eventProps: PropTypes.object,
};
BrandSharingLinks.defaultProps = {
  brand: Map({}),
  eventProps: {},
};

export default React.memo(BrandSharingLinks);
