import getBrandSlug from "./getBrandSlug";

import { formatURL } from "utils/format";

const getBrandUrl = (brandId, brandName) => {
  if (!brandId) {
    return null;
  }

  const brandUrl = `/brands/${getBrandSlug(brandId, brandName)}`;

  return formatURL(brandUrl);
};

export const getBrandUrlByEntity = (brand) => {
  if (!brand) {
    return null;
  }

  const id = brand?.get("id") || brand?.getIn(["_source", "id"]);
  const name = brand?.get("name") || brand?.getIn(["_source", "name"]);

  return getBrandUrl(id, name);
};

export default getBrandUrl;
