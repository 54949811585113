import { css } from "aphrodite";
import PropTypes from "prop-types";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";

import cardStyles from "../../../styles/CardStyles";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const sidePanelMargin = {
  marginBottom: "1rem",
  position: "relative",

  [ScreenSizes.lgAndAbove]: {
    marginBottom: "3rem",
  },
};

const baseStyles = {
  pageSidebarPanel: {
    ...cardStyles.raisedCard,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    position: "relative",
    ...sidePanelMargin,
  },
  pageSidebarPanelMinimal: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    backgroundColor: "#FFF",
    borderRadius: 8,
    ...sidePanelMargin,
  },
  pageSidebarMargin: sidePanelMargin,
};

const PageSidebarPanel = (props) => {
  const {
    children,
    noPanel,
    minimal,
    withMargin,
    className,
    renderOnMount,
    dataId,
    OuterComponent,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const renderPanel = () => (
    <OuterComponent
      className={`${css(
        !noPanel && !minimal && styles.pageSidebarPanel,
        !noPanel && minimal && styles.pageSidebarPanelMinimal,
        withMargin && styles.pageSidebarMargin
      )} ${className}`}
      data-id={dataId}
    >
      {children}
    </OuterComponent>
  );

  if (renderOnMount) {
    return renderPanel();
  }

  return (
    <LazyLoadComponent renderOnMount={renderOnMount} height={250}>
      {renderPanel()}
    </LazyLoadComponent>
  );
};

PageSidebarPanel.propTypes = {
  children: PropTypes.node,
  noPanel: PropTypes.bool,
  minimal: PropTypes.bool,
  className: PropTypes.string,
  renderOnMount: PropTypes.bool,
  withMargin: PropTypes.bool,
  dataId: PropTypes.string,
  OuterComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

PageSidebarPanel.defaultProps = {
  children: null,
  noPanel: false,
  minimal: false,
  className: "",
  renderOnMount: false,
  withMargin: false,
  dataId: "page-sidebar-panel",
  OuterComponent: "div",
};

export default PageSidebarPanel;
