import PropTypes from "prop-types";
import { useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import getBrandSharingLinksInfo from "./getBrandSharingLinksInfo";

import useBrandData from "hooks/useBrandData";

const BrandSharingLinks = (props) => {
  const { brandId } = props;

  const brand = useBrandData(brandId);

  const info = useMemo(() => brand && getBrandSharingLinksInfo(brand), [brand]);

  const eventProps = useMemo(
    () => ({
      brandName: brand.get("name"),
      brandCode: brand.get("code"),
    }),
    [brand]
  );

  return (
    <EntitySharingLinks
      {...props}
      entity={brand}
      entity_type="brand"
      info={info}
      variation="white"
      eventProps={eventProps}
    />
  );
};

BrandSharingLinks.propTypes = {
  brandId: PropTypes.number.isRequired,
};

export default BrandSharingLinks;
