import PropTypes from "prop-types";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

const SpreadTheWordLinks = (props) => (
  <EntitySharingLinks
    {...props}
    info={props.sharingLinksInfo}
    variation="white"
  />
);

SpreadTheWordLinks.propTypes = {
  sharingLinksInfo: PropTypes.object.isRequired,
  eventProps: PropTypes.object,
};

export default SpreadTheWordLinks;
