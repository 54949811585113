import { getBaseUrl } from "constants/base";
import { getBrandUrlByEntity } from "utils/entity/getBrandUrl";

function getBrandSharingLinksInfo(brand) {
  if (!brand) {
    return {};
  }

  return {
    url: `${getBaseUrl()}${getBrandUrlByEntity(brand)}`,
    title: `Podcasts sponsored by ${brand?.get("name")} on Podchaser`,
    twitterTitle: `Podcasts sponsored by ${brand?.get("name")} on @Podchaser`,
    image_url: `${brand?.get("image_url")}`,
  };
}

export default getBrandSharingLinksInfo;
